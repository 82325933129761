<template>
    <div v-loading="loading">
        <el-divider></el-divider>
        <el-row type="flex" justify="space-around" align="middle">
            {{ $t("lang.选择设备") }}<el-select v-model="selectSerialNumber" @change="handleDeviceChange">
                <el-option v-for="(item, index) in myCompanyDevice" :key="index" :label="item.serialNumber + '(' + item.remark + ')'" :value="item.serialNumber">
                </el-option>
            </el-select>
            {{ $t("lang.选择日期") }}<el-date-picker v-model="searchRange" @change="loadData" type="datetimerange" :picker-options="pickerOptions" :range-separator="$t('lang.至')" start-placeholder="开始日期" end-placeholder="结束日期" align="right">
            </el-date-picker>
            {{ $t("lang.电池电量") }}<el-tooltip effect="dark" :content="dianLiang ? $t('lang.返回时间') + '：' + dianLiang.dataTime : $t('lang.无记录')" placement="bottom">
                <el-progress :text-inside="true" :stroke-width="30" :percentage="dianLiang ? dianLiang.dianLiang : 0" style="width:30%;"></el-progress>
            </el-tooltip>
        </el-row>
        <el-divider></el-divider>
        <el-row type="flex" justify="space-between">
            <el-col :span="12">
                <div id="WXCW03_Chart"></div>
            </el-col>
            <el-col :span="12">
                <van-notice-bar v-if="showNoticeBar" left-icon="volume-o" text="系统只保存一年数据，请及时导出！"></van-notice-bar>
                <el-row type="flex" justify="end">
                    <el-button type="primary" icon="el-icon-refresh" @click="loadData">{{ $t("lang.刷新") }}</el-button>
                    <JsonExcel :data="tableData" :fields="selectSerialNumberObject.deviceType == 'WXCW80' ? json_fields1 : json_fields" :name="exportFileName" style="margin-left:20px;">
                        <el-button type="success" icon="el-icon-download">{{ $t("lang.导出") }}</el-button>
                    </JsonExcel>
                </el-row>
                <ve-table ref="myVeTable" style="margin-top:10px;" :table-data="tableData.slice((pageIndex - 1) * pageSize, (pageIndex - 1) * pageSize + pageSize)" rowKeyFieldName="id" :max-height="500" borderY :fixed-header="true" :columns="columns" :editOption="editOption" :footer-data="footerData" :cellStyleOption="cellStyleOption"></ve-table>
                <ve-pagination :total="tableData.length" :pageIndex="pageIndex" :layout="['total', 'prev', 'next', 'jumper']" style="margin-top:10px;" @on-page-number-change="pageNumberChange"></ve-pagination>
            </el-col>

        </el-row>
        <el-empty v-if="!selectSerialNumber" :description="$t('lang.请选择设备')"></el-empty>
    </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import { NoticeBar } from 'vant';
Vue.use(NoticeBar);
//chart相关引用
import HighCharts from "highcharts";
import dark from "highcharts/themes/dark-unica";
import exportHighChart from "highcharts/modules/exporting";
import exportOffHighChart from "highcharts/modules/offline-exporting";
import HighchartsHanHua from "@/util/highcharts-lang-zh_CN.js";
exportHighChart(HighCharts);
exportOffHighChart(HighCharts);
dark(HighCharts);
HighchartsHanHua.HighchartsHanHua(HighCharts);
HighCharts.setOptions({ global: { useUTC: false } });

// 引入样式
import "vue-easytable/libs/theme-default/index.css";
// 引入组件库
import VueEasytable from "vue-easytable";

import { Divider } from "vant";
Vue.use(Divider);

Vue.use(VueEasytable);
//导出相关
import JsonExcel from "vue-json-excel";

export default {
    components: { JsonExcel },
    data() {
        let that = this;
        return {
            pageIndex: 1,
            pageSize: 10,
            dianLiang: null,
            loading: false,
            selectSerialNumber: "",
            selectSerialNumberObject: {},
            showNoticeBar: false,
            cd_Name: { t1: "", t2: "", t3: "", t4: "", t5: "", t6: "", t7: "", t8: "", },
            myCompanyDevice: [],
            searchRange: [moment().startOf("day").toDate(), moment().startOf("day").add(1, "days").toDate()],
            pickerOptions: {
                shortcuts: [
                    {
                        text: "1" + that.$t("lang.天"),
                        onClick(picker) {
                            picker.$emit("pick", [moment().startOf("day").toDate(), moment().startOf("day").add(1, "days").toDate()]);
                        },
                    },
                    {
                        text: "2" + that.$t("lang.天"),
                        onClick(picker) {
                            picker.$emit("pick", [moment().startOf("day").add(-1, "days").toDate(), moment().startOf("day").add(1, "days").toDate()]);
                        },
                    },
                    {
                        text: "3" + that.$t("lang.天"),
                        onClick(picker) {
                            picker.$emit("pick", [moment().startOf("day").add(-2, "days").toDate(), moment().startOf("day").add(1, "days").toDate()]);
                        },
                    },
                    {
                        text: "7" + that.$t("lang.天"),
                        onClick(picker) {
                            picker.$emit("pick", [moment().startOf("day").add(-6, "days").toDate(), moment().startOf("day").add(1, "days").toDate()]);
                        },
                    },
                    {
                        text: "15" + that.$t("lang.天"),
                        onClick(picker) {
                            picker.$emit("pick", [moment().startOf("day").add(-14, "days").toDate(), moment().startOf("day").add(1, "days").toDate()]);
                        },
                    },
                    {
                        text: "30" + that.$t("lang.天"),
                        onClick(picker) {
                            picker.$emit("pick", [moment().startOf("day").add(-29, "days").toDate(), moment().startOf("day").add(1, "days").toDate()]);
                        },
                    },
                ],
            },
            tableData: [],
            columns: [
                { field: "dataTime", key: "dataTime", title: that.$t('lang.返回时间') },
                {
                    field: "t1", key: "t1", title: "T1", edit: true, renderHeaderCell: () => {
                        if (that.cd_Name.t1) {
                            return <el-tooltip placement="top">
                                <div slot="content">{that.$t("lang.工程") + "：" + that.cd_Name.t1.wxcW03_CWGL.wxcW03_GCGL.projectName}<br />{that.$t("lang.测位") + "：" + that.cd_Name.t1.wxcW03_CWGL.name}<br />
                                    {that.$t("lang.测点") + "：" + that.cd_Name.t1.name}</div>
                                <div>T1</div></el-tooltip>;
                        }
                        else {
                            return <el-tooltip placement="top" content={that.$t('lang.未配置工程信息')}><div>T1</div></el-tooltip>;
                        }
                    }
                },
                {
                    field: "t2", key: "t2", title: "T2", edit: true, renderHeaderCell: () => {
                        if (that.cd_Name.t2) {
                            return <el-tooltip placement="top">
                                <div slot="content">{that.$t("lang.工程") + "：" + that.cd_Name.t2.wxcW03_CWGL.wxcW03_GCGL.projectName}<br />
                                    {that.$t("lang.测位") + "：" + that.cd_Name.t2.wxcW03_CWGL.name}<br />
                                    {that.$t("lang.测点") + "：" + that.cd_Name.t2.name}
                                </div>
                                <div>T2</div></el-tooltip>;
                        }
                        else {
                            return <el-tooltip placement="top" content={that.$t('lang.未配置工程信息')}><div>T2</div></el-tooltip>;
                        }
                    }
                },
                {
                    field: "t3", key: "t3", title: "T3", edit: true, renderHeaderCell: () => {
                        if (that.cd_Name.t3) {
                            return <el-tooltip placement="top">
                                <div slot="content">{that.$t("lang.工程") + "：" + that.cd_Name.t3.wxcW03_CWGL.wxcW03_GCGL.projectName}<br />
                                    {that.$t("lang.测位") + "：" + that.cd_Name.t3.wxcW03_CWGL.name}<br />
                                    {that.$t("lang.测点") + "：" + that.cd_Name.t3.name}
                                </div>
                                <div>T3</div></el-tooltip>;
                        }
                        else {
                            return <el-tooltip placement="top" content={that.$t('lang.未配置工程信息')}><div>T3</div></el-tooltip>;
                        }
                    }
                },
                {
                    field: "t4", key: "t4", title: "T4", edit: true, renderHeaderCell: () => {
                        if (that.cd_Name.t4) {
                            return <el-tooltip placement="top">
                                <div slot="content">{that.$t("lang.工程") + "：" + that.cd_Name.t4.wxcW03_CWGL.wxcW03_GCGL.projectName}<br />
                                    {that.$t("lang.测位") + "：" + that.cd_Name.t4.wxcW03_CWGL.name}<br />
                                    {that.$t("lang.测点") + "：" + that.cd_Name.t4.name}
                                </div>
                                <div>T4</div></el-tooltip>;
                        }
                        else {
                            return <el-tooltip placement="top" content={that.$t('lang.未配置工程信息')}><div>T4</div></el-tooltip>;
                        }
                    }
                },
                {
                    field: "t5", key: "t5", title: "T5", edit: true, renderHeaderCell: () => {
                        if (that.cd_Name.t5) {
                            return <el-tooltip placement="top">
                                <div slot="content">{that.$t("lang.工程") + "：" + that.cd_Name.t5.wxcW03_CWGL.wxcW03_GCGL.projectName}<br />
                                    {that.$t("lang.测位") + "：" + that.cd_Name.t5.wxcW03_CWGL.name}<br />
                                    {that.$t("lang.测点") + "：" + that.cd_Name.t5.name}
                                </div>
                                <div>T5</div></el-tooltip>;
                        }
                        else {
                            return <el-tooltip placement="top" content={that.$t('lang.未配置工程信息')}><div>T5</div></el-tooltip>;
                        }
                    }
                },
                {
                    field: "t6", key: "t6", title: "T6", edit: true, renderHeaderCell: () => {
                        if (that.cd_Name.t6) {
                            return <el-tooltip placement="top">
                                <div slot="content">{that.$t("lang.工程") + "：" + that.cd_Name.t6.wxcW03_CWGL.wxcW03_GCGL.projectName}<br />
                                    {that.$t("lang.测位") + "：" + that.cd_Name.t6.wxcW03_CWGL.name}<br />
                                    {that.$t("lang.测点") + "：" + that.cd_Name.t6.name}
                                </div>
                                <div>T6</div></el-tooltip>;
                        }
                        else {
                            return <el-tooltip placement="top" content={that.$t('lang.未配置工程信息')}><div>T6</div></el-tooltip>;
                        }
                    }
                },
                {
                    field: "t7", key: "t7", title: "T7", edit: true, renderHeaderCell: () => {
                        if (that.cd_Name.t7) {
                            return <el-tooltip placement="top">
                                <div slot="content">{that.$t("lang.工程") + "：" + that.cd_Name.t7.wxcW03_CWGL.wxcW03_GCGL.projectName}<br />
                                    {that.$t("lang.测位") + "：" + that.cd_Name.t7.wxcW03_CWGL.name}<br />
                                    {that.$t("lang.测点") + "：" + that.cd_Name.t7.name}
                                </div>
                                <div>T7</div></el-tooltip>;
                        }
                        else {
                            return <el-tooltip placement="top" content={that.$t('lang.未配置工程信息')}><div>T7</div></el-tooltip>;
                        }
                    }
                },
                {
                    field: "t8", key: "t8", title: "T8", edit: true, renderHeaderCell: () => {
                        if (that.cd_Name.t8) {
                            return <el-tooltip placement="top">
                                <div slot="content">{that.$t("lang.工程") + "：" + that.cd_Name.t8.wxcW03_CWGL.wxcW03_GCGL.projectName}<br />
                                    {that.$t("lang.测位") + "：" + that.cd_Name.t8.wxcW03_CWGL.name}<br />
                                    {that.$t("lang.测点") + "：" + that.cd_Name.t8.name}
                                </div>
                                <div>T8</div></el-tooltip>;
                        }
                        else {
                            return <el-tooltip placement="top" content={that.$t('lang.未配置工程信息')}><div>T8</div></el-tooltip>;
                        }
                    }
                },
                {
                    field: "",
                    key: "e",
                    title: that.$t("lang.操作"),
                    renderBodyCell: ({ row, rowIndex }) => {
                        return (
                            <div
                                type="button"
                                value={that.$t('lang.删除')}
                                style="color:#F56C6C;cursor: pointer;"
                                on-click={() =>
                                    that.$confirm(that.$t('lang.确定要删除吗'))
                                        .then(function () {
                                            that.axios.post("WXCW03/DelWXCW03_Result", row).then(() => {
                                                that.tableData.splice((that.pageIndex - 1) * 10 + rowIndex, 1);
                                            });
                                        })
                                        .catch(function () { })
                                }
                            >{that.$t('lang.删除')}</div>
                        );
                    },
                },
            ],
            editOption: {
                beforeCellValueChange: ({ row, column, changeValue }) => {
                    let xiaoShu1 = /^[+-]?\d+(?:\.\d{1})?$/;
                    let notChanged = true;
                    if (column.field == "t1") {
                        notChanged = row.t1 == changeValue;
                        if (!changeValue) {
                            row.t1 = null;
                        } else if (xiaoShu1.test(changeValue)) {
                            row.t1 = parseFloat(changeValue);
                        } else {
                            that.$message({ type: "error", message: that.$t('lang.数字格式不正确') });
                            return false;
                        }
                    } else if (column.field == "t2") {
                        notChanged = row.t2 == changeValue;
                        if (!changeValue) {
                            row.t2 = null;
                        } else if (xiaoShu1.test(changeValue)) {
                            row.t2 = parseFloat(changeValue);
                        } else {
                            that.$message({ type: "error", message: that.$t('lang.数字格式不正确') });
                            return false;
                        }
                    } else if (column.field == "t3") {
                        notChanged = row.t3 == changeValue;
                        if (!changeValue) {
                            row.t3 = null;
                        } else if (xiaoShu1.test(changeValue)) {
                            row.t3 = parseFloat(changeValue);
                        } else {
                            that.$message({ type: "error", message: that.$t('lang.数字格式不正确') });
                            return false;
                        }
                    } else if (column.field == "t4") {
                        notChanged = row.t4 == changeValue;
                        if (!changeValue) {
                            row.t4 = null;
                        } else if (xiaoShu1.test(changeValue)) {
                            row.t4 = parseFloat(changeValue);
                        } else {
                            that.$message({ type: "error", message: that.$t('lang.数字格式不正确') });
                            return false;
                        }
                    } else if (column.field == "t5") {
                        notChanged = row.t5 == changeValue;
                        if (!changeValue) {
                            row.t5 = null;
                        } else if (xiaoShu1.test(changeValue)) {
                            row.t5 = parseFloat(changeValue);
                        } else {
                            that.$message({ type: "error", message: that.$t('lang.数字格式不正确') });
                            return false;
                        }
                    } else if (column.field == "t6") {
                        notChanged = row.t6 == changeValue;
                        if (!changeValue) {
                            row.t6 = null;
                        } else if (xiaoShu1.test(changeValue)) {
                            row.t6 = parseFloat(changeValue);
                        } else {
                            that.$message({ type: "error", message: that.$t('lang.数字格式不正确') });
                            return false;
                        }
                    } else if (column.field == "t7") {
                        notChanged = row.t7 == changeValue;
                        if (!changeValue) {
                            row.t7 = null;
                        } else if (xiaoShu1.test(changeValue)) {
                            row.t7 = parseFloat(changeValue);
                        } else {
                            that.$message({ type: "error", message: that.$t('lang.数字格式不正确') });
                            return false;
                        }
                    } else if (column.field == "t8") {
                        notChanged = row.t8 == changeValue;
                        if (!changeValue) {
                            row.t8 = null;
                        } else if (xiaoShu1.test(changeValue)) {
                            row.t8 = parseFloat(changeValue);
                        } else {
                            that.$message({ type: "error", message: that.$t('lang.数字格式不正确') });
                            return false;
                        }
                    } else if (column.field == "dataTime") {
                        notChanged = row.dataTime == changeValue;
                        let reDateTime = /^(?:19|20)[0-9][0-9]-(?:(?:0[1-9])|(?:1[0-2]))-(?:(?:[0-2][1-9])|(?:[1-3][0-1])) (?:(?:[0-2][0-3])|(?:[0-1][0-9])):[0-5][0-9]:[0-5][0-9]$/;
                        if (reDateTime.test(changeValue)) {
                            row.dataTime = changeValue;
                        } else {
                            that.$message({ type: "error", message: that.$t('lang.时间格式不正确') });
                            return false;
                        }
                    }
                    if (!notChanged) {
                        that.axios.post("WXCW03/UpdateWXCW03_Result", row).then((response) => {
                            if (response.data.code == 101) {
                                that.$message({ type: "success", message: that.$t('lang.保存成功') });
                            } else {
                                that.$message({ type: "error", message: that.$t('lang.保存失败') });
                            }
                        });
                    }
                },
                afterCellValueChange: ({ row, column, changeValue }) => {
                    if (column.field == "t1") {
                        if (changeValue) {
                            row.t1 = parseFloat(changeValue);
                        } else {
                            row.t1 = null;
                        }
                    } else if (column.field == "t2") {
                        if (changeValue) {
                            row.t2 = parseFloat(changeValue);
                        } else {
                            row.t2 = null;
                        }
                    } else if (column.field == "t3") {
                        if (changeValue) {
                            row.t3 = parseFloat(changeValue);
                        } else {
                            row.t3 = null;
                        }
                    } else if (column.field == "t4") {
                        if (changeValue) {
                            row.t4 = parseFloat(changeValue);
                        } else {
                            row.t4 = null;
                        }
                    } else if (column.field == "t5") {
                        if (changeValue) {
                            row.t5 = parseFloat(changeValue);
                        } else {
                            row.t5 = null;
                        }
                    } else if (column.field == "t6") {
                        if (changeValue) {
                            row.t6 = parseFloat(changeValue);
                        } else {
                            row.t6 = null;
                        }
                    } else if (column.field == "t7") {
                        if (changeValue) {
                            row.t7 = parseFloat(changeValue);
                        } else {
                            row.t7 = null;
                        }
                    } else if (column.field == "t8") {
                        if (changeValue) {
                            row.t8 = parseFloat(changeValue);
                        } else {
                            row.t8 = null;
                        }
                    }
                },
            },
            footerData: [],
            json_fields: { 采集时间: "dataTime", T1: "t1", T2: "t2", T3: "t3", T4: "t4", T5: "t5", T6: "t6", T7: "t7", T8: "t8" },
            json_fields1: { 采集时间: "dataTime", T1: "t1", T2: "t2", T3: "t3", T4: "t4" },
            cellStyleOption: {
                footerCellClass: ({ column }) => {
                    if (column.field != "dataTime") {
                        return "wxcw03-device-footer-avg";
                    }
                },
            },
        };
    },
    computed: {
        exportFileName() {
            let that = this;
            if (that.searchRange) {
                return that.selectSerialNumber + "(" + moment(that.searchRange[0]).format("YYYY-MM-DD HH:mm:ss") + "-" + moment(that.searchRange[1]).format("YYYY-MM-DD HH:mm:ss") + ")";
            } else {
                return that.selectSerialNumber;
            }
        },
    },
    methods: {
        pageNumberChange(pageIndex) {
            this.pageIndex = pageIndex;
        },
        loadDianLiang() {
            let that = this;
            that.axios
                .post("WXCW03/GetDeviceDianChiDianLiang", {
                    onlyText: that.selectSerialNumber,
                })
                .then((response) => {
                    that.dianLiang = response.data.data;
                });
        },
        handleDeviceChange(v) {
            let that = this;
            that.selectSerialNumberObject = that.myCompanyDevice.find((ele) => { return ele.serialNumber == v; });
            that.showNoticeBar = moment().add(90, "days").valueOf() > moment(that.selectSerialNumberObject.expiresDate).valueOf();
            that.loadDianLiang();
            that.loadData();
        },
        loadCDName() {
            let that = this;
            that.axios.post("WXCW03/GetLastCeDianName", { onlyText: that.selectSerialNumber }).then((response) => {
                that.cd_Name.t1 = response.data.data.find(l => l.passageway == "T1");
                that.cd_Name.t2 = response.data.data.find(l => l.passageway == "T2");
                that.cd_Name.t3 = response.data.data.find(l => l.passageway == "T3");
                that.cd_Name.t4 = response.data.data.find(l => l.passageway == "T4");
                that.cd_Name.t5 = response.data.data.find(l => l.passageway == "T5");
                that.cd_Name.t6 = response.data.data.find(l => l.passageway == "T6");
                that.cd_Name.t7 = response.data.data.find(l => l.passageway == "T7");
                that.cd_Name.t8 = response.data.data.find(l => l.passageway == "T8");
            });
        },
        loadData() {
            let that = this;
            that.loadCDName();
            if (that.selectSerialNumber && that.searchRange && that.searchRange.length > 1) {
                that.loading = true;
                that.pageIndex = 1;
                that.axios
                    .post("WXCW03/GetDeviceData", {
                        serialNumber: that.selectSerialNumber,
                        beginDate: that.searchRange[0],
                        endDate: that.searchRange[1],
                    })
                    .then((response) => {
                        that.footerData = response.data.data.splice(response.data.data.length - 1, 1);
                        that.footerData[0].dataTime = that.$t("lang.平均值");
                        that.tableData = response.data.data;
                        var mapData = [];
                        mapData.push({ name: "T1", data: [] });
                        mapData.push({ name: "T2", data: [] });
                        mapData.push({ name: "T3", data: [] });
                        mapData.push({ name: "T4", data: [] });
                        if (that.selectSerialNumberObject.deviceType != "WXCW80") {
                            mapData.push({ name: "T5", data: [] });
                            mapData.push({ name: "T6", data: [] });
                            mapData.push({ name: "T7", data: [] });
                            mapData.push({ name: "T8", data: [] });
                        }
                        for (var i = 0; i < that.tableData.length; i++) {
                            if (that.tableData[i].t1 != undefined) mapData[0].data.push([moment(that.tableData[i].dataTime).valueOf(), that.tableData[i].t1]);
                            if (that.tableData[i].t2 != undefined) mapData[1].data.push([moment(that.tableData[i].dataTime).valueOf(), that.tableData[i].t2]);
                            if (that.tableData[i].t3 != undefined) mapData[2].data.push([moment(that.tableData[i].dataTime).valueOf(), that.tableData[i].t3]);
                            if (that.tableData[i].t4 != undefined) mapData[3].data.push([moment(that.tableData[i].dataTime).valueOf(), that.tableData[i].t4]);
                            if (that.selectSerialNumberObject.deviceType != "WXCW80") {
                                if (that.tableData[i].t5 != undefined) mapData[4].data.push([moment(that.tableData[i].dataTime).valueOf(), that.tableData[i].t5]);
                                if (that.tableData[i].t6 != undefined) mapData[5].data.push([moment(that.tableData[i].dataTime).valueOf(), that.tableData[i].t6]);
                                if (that.tableData[i].t7 != undefined) mapData[6].data.push([moment(that.tableData[i].dataTime).valueOf(), that.tableData[i].t7]);
                                if (that.tableData[i].t8 != undefined) mapData[7].data.push([moment(that.tableData[i].dataTime).valueOf(), that.tableData[i].t8]);
                            }
                        }
                        that.loadCharts(mapData);
                        that.loading = false;

                    });
                //控制t5到t8的显示
                that.$nextTick(function () {
                    if (that.selectSerialNumberObject.deviceType == "WXCW80") {
                        that.$refs["myVeTable"].hideColumnsByKeys(['t5', 't6', 't7', 't8']);
                    }
                    else {
                        that.$refs["myVeTable"].showColumnsByKeys(['t5', 't6', 't7', 't8']);
                    }
                });
            }
        },
        loadMyCompanyDevice() {
            let that = this;
            that.axios
                .post("WXCW03/GetMyCompanyDevice1", {
                    deviceType: "WXCW", //这里以后改成WXCW03
                })
                .then((response) => {
                    that.myCompanyDevice = response.data.data;
                });
        },
        loadCharts(mapData) {
            let that = this;
            HighCharts.chart("WXCW03_Chart", {
                lang: {
                    viewFullscreen: that.$t("lang.全屏"),
                    printChart: that.$t("lang.打印图表"),
                    downloadJPEG: that.$t("lang.导出JPEG"),
                    downloadPDF: that.$t("lang.导出PDF"),
                    downloadPNG: that.$t("lang.导出PNG"),
                    downloadSVG: that.$t("lang.导出SVG"),
                },
                tooltip: {
                    formatter: function () {
                        return this.series.name + "<br />" + that.$t("lang.温度") + "：" + this.y + "℃<br />" + that.$t("lang.时间") + "：" + moment(this.x).format("YYYY-MM-DD HH:mm:ss");
                    },
                },
                plotOptions: { series: { turboThreshold: 0 } }, //大于一千个点要用这个。否则不显示。
                title: { text: that.$t("lang.温度曲线图") },
                credits: { enabled: false },
                //global: { useUTC: false },这里要在开头的时候初始化，而不是这里。
                yAxis: { title: { text: that.$t("lang.温度") } },
                xAxis: { type: "datetime" },
                series: mapData,
            });
        },
    },
    mounted() {
        let that = this;
        that.loadMyCompanyDevice();
        that.loadCharts([]);


    },
};
</script>

<style>
.wxcw03-device-footer-avg {
    background: orange !important;
    color: #fff !important;
}
</style>